import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Layout/Header";
import { useSelector } from "react-redux";
import socketIO from "socket.io-client";
import { format } from "timeago.js";
import { server } from "../server";
import axios from "axios";
import { AiOutlineArrowLeft, AiOutlineSend } from "react-icons/ai";
import { TfiGallery } from "react-icons/tfi";
import MessageList from "./MessageList.jsx";


const ENDPOINT = process.env.REACT_APP_SOCKET;
const socket = socketIO(ENDPOINT, { transports: ["websocket"] });

const UserInbox = () => {
  const { user } = useSelector((state) => state.user);
  const [conversations, setConversations] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [currentChat, setCurrentChat] = useState();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [shopData, setShopData] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [images, setImages] = useState();
  const [activeStatus, setActiveStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchShopData = async () => {
      if (currentChat) {
        try {
          setIsLoading(true);
          const sellerId = currentChat.members.find((m) => m !== user._id); // Encontrar o ID do vendedor
          const response = await axios.get(`${server}/shop/get-shop-info/${sellerId}`);
          setShopData(response.data.shop);
          setIsLoading(false);
        } catch (error) {
          console.log(error);
          setIsLoading(false);
        }
      }
    };
    fetchShopData();
  }, [currentChat, user._id]);

  useEffect(() => {
    socket.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  useEffect(() => {
    if (arrivalMessage && currentChat?.members.includes(arrivalMessage.sender)) {
      setMessages((prev) => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage, currentChat]);

  useEffect(() => {
    const getConversations = async () => {
      try {
        const response = await axios.get(
          `${server}/conversation/get-all-conversation-user/${user?._id}`,
          { withCredentials: true }
        );
        setConversations(response.data.conversations);
      } catch (error) {
        console.log(error);
      }
    };
    if (user) {
      getConversations();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      socket.emit("addUser", user._id);
      socket.on("getUsers", (data) => {
        setOnlineUsers(data);
      });
    }
  }, [user]);

  const onlineCheck = (chat) => {
    const chatMembers = chat.members.find((member) => member !== user?._id);
    return onlineUsers.some((onlineUser) => onlineUser.userId === chatMembers);
  };

  useEffect(() => {
    const getMessages = async () => {
      try {
        const response = await axios.get(
          `${server}/message/get-all-messages/${currentChat?._id}`
        );
        setMessages(response.data.messages);
      } catch (error) {
        console.log(error);
      }
    };
    if (currentChat) {
      getMessages();
    }
  }, [currentChat]);

  const sendMessageHandler = async (e) => {
    e.preventDefault();
    const message = {
      sender: user._id,
      text: newMessage,
      conversationId: currentChat._id,
    };
    const receiverId = currentChat.members.find((member) => member !== user._id);

    socket.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      text: newMessage,
    });

    try {
      if (newMessage) {
        const res = await axios.post(`${server}/message/create-new-message`, message);
        setMessages([...messages, res.data.message]);
        updateLastMessage(newMessage);
        setNewMessage("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessage = async (lastMessage) => {
    await axios.put(`${server}/conversation/update-last-message/${currentChat._id}`, {
      lastMessage,
      lastMessageId: user._id,
    });
  };

  const handleImageUpload = async (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImages(reader.result);
        imageSendingHandler(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const imageSendingHandler = async (image) => {
    const receiverId = currentChat.members.find((member) => member !== user._id);

    socket.emit("sendMessage", {
      senderId: user._id,
      receiverId,
      images: image,
    });

    try {
      const res = await axios.post(`${server}/message/create-new-message`, {
        images: image,
        sender: user._id,
        conversationId: currentChat._id,
      });
      setMessages([...messages, res.data.message]);
      updateLastMessageForImage();
      setImages(null);
    } catch (error) {
      console.log(error);
    }
  };

  const updateLastMessageForImage = async () => {
    await axios.put(`${server}/conversation/update-last-message/${currentChat._id}`, {
      lastMessage: "Photo",
      lastMessageId: user._id,
    });
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  return (
    <div className="w-full bg-gray-100 text-gray-900">
      {!open && (
        <>
          <Header />
          <h1 className="text-center text-3xl py-3 font-semibold">Chats</h1>
          <div className="max-w-4xl mx-auto p-3">
            {conversations.map((item, index) => {
              return (
                <MessageList
                  key={index}
                  data={item}
                  setOpen={setOpen}
                  setCurrentChat={setCurrentChat}
                  shopData={shopData} // Pass shopData as a prop
                  me={user._id}
                  setShopData={setShopData} // Update here
                  online={onlineCheck(item)}
                  setActiveStatus={setActiveStatus}
                />
              );
            })}
          </div>
        </>
      )}

      {open && shopData && (
        <div className="min-h-screen flex flex-col bg-white">
          <div className="w-full flex p-3 items-center justify-between bg-gray-200 border-b border-gray-300">
            <div className="flex items-center">
              <AiOutlineArrowLeft
                size={25}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
              <img
                src={shopData.avatar?.url}
                alt="Shop Avatar"
                className="w-10 h-10 rounded-full ml-3"
              />
              <div className="ml-3">
                <h1 className="text-lg font-semibold">{shopData.name}</h1>
                <p className="text-sm text-gray-500">Online Status: {activeStatus ? "Online" : "Offline"}</p>
              </div>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((item, index) => (
              <div
                key={index}
                className={`flex items-end mb-4 ${item.sender === user._id ? "justify-end" : "justify-start"}`}
                ref={scrollRef}
              >
                {item.sender !== user._id && (
                  <img
                    src={shopData.avatar?.url}
                    alt="Avatar"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                )}
                <div
                  className={`py-2 px-3 rounded-lg max-w-xs ${
                    item.sender === user._id ? "bg-green-500 text-white" : "bg-white border border-gray-300 text-gray-900"
                  }`}
                >
                  <p className="text-xs text-gray-500">{item.sender === user._id ? "You" : shopData?.name}</p>
                  {item.text && <p>{item.text}</p>}
                  {item.images && (
                    <img
                      src={item.images}
                      alt=""
                      className="rounded-lg mt-2"
                      style={{ maxWidth: "200px" }}
                    />
                  )}
                  <p className="text-xs text-gray-500 mt-1">{format(item.createdAt)}</p>
                </div>
              </div>
            ))}
          </div>

          <form
            className="flex items-center justify-between p-3 border-t border-gray-300"
            onSubmit={sendMessageHandler}
          >
            <div>
              <input
                type="file"
                id="image"
                className="hidden"
                onChange={handleImageUpload}
              />
              <label htmlFor="image" className="cursor-pointer text-gray-700">
                <TfiGallery size={20} />
              </label>
            </div>
            <input
              type="text"
              placeholder="Enter your message..."
              className="flex-1 mx-3 py-2 px-4 rounded-full border border-gray-300 focus:outline-none"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
            />
            <button
              type="submit"
              className="w-10 h-10 rounded-full flex items-center justify-center bg-blue-500 text-white"
            >
              <AiOutlineSend size={20} />
            </button>
          </form>
        </div>
      )}
    </div>
  );
};

export default UserInbox;
