import React from "react";
//import "./styles.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
//import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";

// create custom icon
const customIcon = new Icon({
  iconUrl:require("../Icon/location-pin.png"),
  iconSize: [38, 38], // size of the icon
});


// markers
const markers = [
  {
    geocode: [0.33991, 6.72679],
    popUp: "Loja atrás de continental",
  },
  {
    geocode: [0.314726, 6.742753],
    popUp: "Armazém Pantufo",
  },
  {
    geocode: [0.329216, 6.729686],
    popUp: "Fruta-Fruta",
  },
];

const MapPage = () => {
  return (
    <>
      <Header activeHeading={6} />
      <div style={{ height: "calc(100vh - 170px)", marginTop: "1px"}}>
        <MapContainer center={[0.337371, 6.730457]} zoom={13} style={{ height: "100%", width: "100%" }}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
         
            {markers.map((marker, index) => (
              <Marker key={index} position={marker.geocode} icon={customIcon}>
                <Popup>{marker.popUp}</Popup>
              </Marker>
            ))}
         
        </MapContainer>
      </div>
    
    </>
  );
};

export default  MapPage;
