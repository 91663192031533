import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../styles/styles";
import ProductCard from "../Route/ProductCard/ProductCard";

// Função simples para calcular a similaridade das palavras
const calculateSimilarity = (word1, word2) => {
  const minLength = Math.min(word1.length, word2.length);
  let commonChars = 0;

  for (let i = 0; i < minLength; i++) {
    if (word1[i] === word2[i]) {
      commonChars++;
    }
  }

  return commonChars / Math.max(word1.length, word2.length);
};

const SuggestedProduct = ({ data }) => {
  const { allProducts } = useSelector((state) => state.products);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    if (data && allProducts) {
      const dataTags = Array.isArray(data.tags) ? data.tags : [data.tags];

      // Helper function to count the number of similar words between two tag arrays
      const countSimilarWords = (productTags, dataTags) => {
        const productTagWords = Array.isArray(productTags)
          ? productTags.map(tag => tag.split(' ')).flat()
          : [];
        const dataTagWords = dataTags.map(tag => tag.split(' ')).flat();
        let similarityScore = 0;

        dataTagWords.forEach(dataWord => {
          productTagWords.forEach(productWord => {
            similarityScore += calculateSimilarity(dataWord.toLowerCase(), productWord.toLowerCase());
          });
        });

        return similarityScore;
      };

      // Filter products with the same category, excluding the current product
      const categoryFilteredProducts = allProducts.filter(
        product => product.category === data.category && product._id !== data._id
      );

      // Sort products by the similarity score of words in tags (priority)
      const sortedProducts = categoryFilteredProducts.sort((a, b) => {
        const similarityA = countSimilarWords(a.tags, dataTags);
        const similarityB = countSimilarWords(b.tags, dataTags);
        return similarityB - similarityA;
      });

      // Ensure only up to 5 products are displayed
      setProductData(sortedProducts.slice(0, 5));
    }
  }, [allProducts, data]);

  return (
    <div>
      {productData.length > 0 && (
        <div className={`p-4 ${styles.section}`}>
          <h2
            className={`${styles.heading} text-[25px] font-[500] border-b mb-5`}
          >
            Produtos Relacionados
          </h2>
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 mb-12">
            {productData.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SuggestedProduct;
