// src/components/Layout/WhatsAppButton.js
import React from 'react';
import { AiOutlineWhatsApp } from 'react-icons/ai';
import { phoneNumber, message } from '../../config/whatsappConfig';

const WhatsAppButton = () => {
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <a 
      href={whatsappLink} 
      target="_blank" 
      rel="noopener noreferrer"
      className="fixed bottom-4 right-4 bg-green-500 text-white p-4 rounded-full shadow-lg"
      title="Fale"
    >
      <AiOutlineWhatsApp size={30} />
    </a>
  );
}

export default WhatsAppButton;
