import React, { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import ProductDetails from "../components/Products/ProductDetails";
import SuggestedProduct from "../components/Products/SuggestedProduct";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../server";

const ProductDetailsPage = () => {
  const { allProducts } = useSelector((state) => state.products);
  const { allEvents } = useSelector((state) => state.events);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [searchParams] = useSearchParams();
  const eventData = searchParams.get("isEvent");
  const [loading, setLoading] = useState(true);

  const registerView = async (productId) => {
    try {
      await axios.post(`${server}/product/${productId}/view`, {}, { withCredentials: true });
    } catch (error) {
      console.error("Error registering product view", error);
    }
  };

  useEffect(() => {
    if (eventData !== null) {
      const eventData = allEvents && allEvents.find((i) => i._id === id);
      setData(eventData);
      setLoading(false);
    } else {
      const productData = allProducts && allProducts.find((i) => i._id === id);
      setData(productData);
      setLoading(false);

      if (productData) {
        // Registrar visualização
        registerView(productData._id);
      }
    }
  }, [allProducts, allEvents, id, eventData]);

  return (
    <div className="min-h-screen flex flex-col mt-4 md:mt-8"> {/* Adicionando margem superior */}
      <Header />
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <p>Carregando...</p>
        </div>
      ) : (
        <div className="flex-1">
          <ProductDetails data={data} />
          {!eventData && data && <SuggestedProduct data={data} />}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
