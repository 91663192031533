import React, { useEffect, useState } from "react";
import { RxCross1, RxCrossCircled } from "react-icons/rx";
import { IoBagHandleOutline } from "react-icons/io5";
import { HiOutlineMinus, HiPlus } from "react-icons/hi";
import styles from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addTocart, removeFromCart, clearCart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import axios from "axios";
import socketIO from "socket.io-client";
import { server } from "../../server";

const ENDPOINT = process.env.REACT_APP_SOCKET;
const socketId = socketIO(ENDPOINT, { transports: ["websocket"] });
const formatPrice = (price) => {
  return price.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
};
const Cart = ({ setOpenCart }) => {
  const { cart } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [arrivalMessage, setArrivalMessage] = useState(null);

  useEffect(() => {
    socketId.on("getMessage", (data) => {
      setArrivalMessage({
        sender: data.senderId,
        text: data.text,
        createdAt: Date.now(),
      });
    });
  }, []);

  const removeFromCartHandler = (data) => {
    dispatch(removeFromCart(data));
  };

  const totalPrice = cart.reduce(
    (acc, item) => acc + item.qty * item.discountPrice,
    0
  );

  const quantityChangeHandler = (data) => {
    dispatch(addTocart(data));
  };
  
  const itemText = cart && cart.length > 1 ? "items" : "item";

  const sendCartDetails = async () => {
    if (!user) {
      toast.error("Por favor, faça login para continuar.");
      navigate("/login");
      return;
    }

    try {
      const senderId = user._id;

      const response = await axios.post(
        `${server}/message/create-new-message-with-cart`,
        {
          senderId,
          cartDetails: cart,
        },
        { withCredentials: true }
      );

      if (response.data.success) {
        toast.success("Pedido enviado com sucesso!");
        dispatch(clearCart());
        navigate("/inbox");
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Ocorreu um erro ao enviar o pedido.");
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] 800px:w-[25%] bg-white flex flex-col overflow-y-scroll justify-between shadow-sm">
        {cart && cart.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenCart(false)}
              />
            </div>
            <h5>Carinho está vazio!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  style={{ color: "red" }}
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenCart(false)}
                />
              </div>
              {/* Item length */}
              <div className={`${styles.noramlFlex} p-4`}>
                <IoBagHandleOutline size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {`${cart && cart.length} ${itemText} `}
                </h5>
              </div>

              {/* cart Single Items */}
              <br />
              <div className="w-full border-t">
                {cart &&
                  cart.map((i, index) => (
                    <CartSingle
                      key={index}
                      data={i}
                      quantityChangeHandler={quantityChangeHandler}
                      removeFromCartHandler={removeFromCartHandler}
                    />
                  ))}
              </div>
            </div>

            <div className="px-5 mb-20">
              {/* checkout buttons */}

              <div
                className={`h-[55px] mb-30 flex items-center justify-center w-[100%] bg-[#4caf50] rounded-[5px] mt-2 cursor-pointer`}
                onClick={sendCartDetails}
              >
                <h1 className="text-[#fff] text-[16px] font-[600]">
                  Enviar o carinho agora (Dbs {formatPrice(totalPrice)})
                </h1>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({
  data,
  quantityChangeHandler,
  removeFromCartHandler,
}) => {
  const [value, setValue] = useState(data.qty);
  const totalPrice = data.discountPrice * value;

  const increment = (data) => {
    if (data.stock < value) {
      toast.error("Product stock limited!");
    } else {
      setValue(value + 1);
      const updateCartData = { ...data, qty: value + 1 };
      quantityChangeHandler(updateCartData);
    }
  };

  const decrement = (data) => {
    setValue(value === 1 ? 1 : value - 1);
    const updateCartData = { ...data, qty: value === 1 ? 1 : value - 1 };
    quantityChangeHandler(updateCartData);
  };

  return (
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <div>
          <div
            className={`bg-[#e44343] border border-[#e4434373] rounded-full w-[25px] h-[25px] ${styles.noramlFlex} justify-center cursor-pointer`}
            onClick={() => increment(data)}
          >
            <HiPlus size={18} color="#fff" />
          </div>
          <span className="pl-[10px]">{data.qty}</span>
          <div
            className="bg-[#a7abb14f] rounded-full w-[25px] h-[25px] flex items-center justify-center cursor-pointer"
            onClick={() => decrement(data)}
          >
            <HiOutlineMinus size={16} color="#7d879c" />
          </div>
        </div>
        <img
          src={`${data?.images[0]?.url}`}
          alt=""
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1>{data.name}</h1>
          <h4 className="font-[400] text-[15px] text-[#00000082]">
            Dbs{data.discountPrice} * {value}
          </h4>
          <h4 className="font-[600] text-[17px] pt-[3px] text-[#d02222] font-Roboto">
            Dbs {totalPrice}
          </h4>
        </div>
        <div className="ml-[20] mb-40">
          <RxCrossCircled
            className="cursor-pointer"
            size={20}
            style={{ color: "red" }}
            onClick={() => removeFromCartHandler(data)}
          />
        </div>
      </div>
    </div>
  );
};

export default Cart;
