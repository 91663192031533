import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import axios from "axios";
import { Line } from "react-chartjs-2";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSearchParams } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ProductStats = () => {
  const [searchParams] = useSearchParams();
  const productId = searchParams.get("productId");
  const [stats, setStats] = useState({ dates: [], views: [] });
  const [totalViews, setTotalViews] = useState(0);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const { data } = await axios.get(`${server}/product/${productId}/view`);
        const dates = data.views.map(view => new Date(view.date).toLocaleDateString());
        const views = data.views.map(view => view.count);
        const total = views.reduce((acc, curr) => acc + curr, 0);
        setStats({ dates, views });
        setTotalViews(total);
      } catch (error) {

        console.log("Error fetching product statistics", error);
      }
    };

    if (productId) {
      fetchStats();
    }
  }, [productId]);

  const data = {
    labels: stats.dates,
    datasets: [
      {
        label: "Views",
        data: stats.views,
        fill: false,
        backgroundColor: "rgb(75, 192, 192)",
        borderColor: "rgba(75, 192, 192, 0.2)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0,
        },
      },
    },
  };

  return (
    <div className="w-full p-8 bg-white shadow rounded mt-4">
      <h3 className="text-[22px] font-Poppins pb-2">Product Statistics</h3>
      <div className="flex flex-col items-center">
        <div className="bg-blue-500 text-white p-4 rounded mb-4">
          <h4 className="text-lg font-bold">Total Views</h4>
          <p className="text-2xl">{totalViews}</p>
        </div>
        <div className="w-full">
          <Line data={data} options={options} />
        </div>
      </div>
    </div>
  );
};

export default ProductStats;
