import React, { useEffect, useState } from "react";
import {
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineMessage,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getAllProductsShop } from "../../redux/actions/product";
import { server } from "../../server";
import styles from "../../styles/styles";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { toast } from "react-toastify";
import Ratings from "./Ratings";
import axios from "axios";

const formatPrice = (price) => {
  return price.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
};

const ProductDetails = ({ data }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);
  const [count, setCount] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllProductsShop(data?.shop._id));
    setClick(wishlist?.some((item) => item._id === data?._id));
  }, [data, wishlist]);

  const incrementCount = () => setCount((prevCount) => prevCount + 1);
  const decrementCount = () => setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));

  const toggleWishlist = () => {
    setClick(!click);
    if (click) {
      dispatch(removeFromWishlist(data));
    } else {
      dispatch(addToWishlist(data));
    }
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart?.some((item) => item._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else if (data.stock < 1) {
      toast.error("Product stock limited!");
    } else {
      const cartData = { ...data, qty: count };
      dispatch(addTocart(cartData));
      toast.success("Item added to cart successfully!");
    }
  };

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      try {
        const groupTitle = data._id + user._id;
        const response = await axios.post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId: user._id,
          sellerId: data.shop._id,
        });
        navigate(`/inbox?${response.data.conversation._id}`);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    } else {
      toast.error("Please login to create a conversation");
    }
  };

  const totalReviewsLength = products?.reduce((acc, product) => acc + product.reviews.length, 0) || 0;
  const totalRatings = products?.reduce(
    (acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0),
    0
  ) || 0;
  const averageRating = (totalRatings / totalReviewsLength || 0).toFixed(2);

  return (
    <div className="bg-white mt-4 md:mt-8"> {/* Adicionando margem superior */}
      {data && (
        <div className={`${styles.section} w-[90%] 800px:w-[80%] mx-auto`}> {/* Centralizando o conteúdo */}
          <div className="w-full py-5">
            <div className="flex flex-col 800px:flex-row">
              <div className="w-full 800px:w-[50%] mb-4 800px:mb-0">
                <img src={data.images[select]?.url} alt="" className="w-full h-auto object-cover rounded-lg" />
                <div className="flex overflow-x-scroll mt-3">
                  {data.images.map((image, index) => (
                    <div
                      key={index}
                      className={`cursor-pointer mr-3 ${select === index ? "border-2 border-teal-500" : ""}`}
                      onClick={() => setSelect(index)}
                    >
                      <img src={image?.url} alt="" className="h-[100px] w-auto object-cover rounded-md" />
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full 800px:w-[50%]">
                <h1 className={`${styles.productTitle} text-2xl font-bold`}>{data.name}</h1>
                <div className="flex pt-3">
                  {data.discountPrice !== data.originalPrice && (
                    <h5 className={`${styles.productDiscountPrice} text-xl font-bold`}>
                      {formatPrice(data.discountPrice)} Dbs
                    </h5>
                  )}
                  <h4 className={`${styles.price} text-xl font-semibold`}>
                    {data.originalPrice ? `${formatPrice(data.originalPrice)} Db` : null}
                  </h4>
                </div>
                <div className="flex items-center mt-8">
                  <button
                    className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-l px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                    onClick={decrementCount}
                  >
                    -
                  </button>
                  <span className="bg-gray-200 text-gray-800 font-medium px-4 py-2">{count}</span>
                  <button
                    className="bg-gradient-to-r from-teal-400 to-teal-500 text-white font-bold rounded-r px-4 py-2 shadow-lg hover:opacity-75 transition duration-300 ease-in-out"
                    onClick={incrementCount}
                  >
                    +
                  </button>
                  <div className="ml-4">
                    {click ? (
                      <AiFillHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={toggleWishlist}
                        color="red"
                        title="Remove from wishlist"
                      />
                    ) : (
                      <AiOutlineHeart
                        size={30}
                        className="cursor-pointer"
                        onClick={toggleWishlist}
                        color="#333"
                        title="Add to wishlist"
                      />
                    )}
                  </div>
                </div>
                <div
                  className={`${styles.button} !mt-6 !rounded !h-11 flex items-center cursor-pointer`}
                  onClick={() => addToCartHandler(data._id)}
                >
                  <span className="text-white flex items-center text-lg ml-2">
                    Adicionar ao Carrinho <AiOutlineShoppingCart className="ml-2" />
                  </span>
                </div>
                <div className="flex items-center pt-8">
                  <Link to={`/shop/preview/${data?.shop._id}`}>
                    <img
                      src={data?.shop?.avatar?.url}
                      alt=""
                      className="w-[50px] h-[50px] rounded-full mr-2"
                    />
                  </Link>
                  <div>
                    <Link to={`/shop/preview/${data?.shop._id}`}>
                      <h3 className={`${styles.shop_name} text-lg font-semibold`}>{data.shop.name}</h3>
                    </Link>
                    <h5 className="pb-3 text-sm">({averageRating}/5) Ratings</h5>
                  </div>
                  <div
                    className={`${styles.button} bg-[#6443d1] mt-4 !rounded !h-11 cursor-pointer`}
                    onClick={handleMessageSubmit}
                  >
                    <span className="text-white flex items-center">
                      Enviar Mensagem <AiOutlineMessage className="ml-1" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ProductDetailsInfo
            data={data}
            products={products}
            totalReviewsLength={totalReviewsLength}
            averageRating={averageRating}
          />
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

const ProductDetailsInfo = ({ data, products, totalReviewsLength, averageRating }) => {
  const [active, setActive] = useState(1);

  return (
    <div className="bg-[#f5f6fb] px-3 800px:px-10 py-2 rounded">
      <div className="w-full flex justify-between border-b pt-10 pb-2">
        <div className="relative">
          <h5
            className="text-[#000] text-lg px-1 leading-5 font-semibold cursor-pointer"
            onClick={() => setActive(1)}
          >
            Detalhes do Produto
          </h5>
          {active === 1 && <div className={`${styles.active_indicator}`} />}
        </div>
        <div className="relative">
          <h5
            className="text-[#000] text-lg px-1 leading-5 font-semibold cursor-pointer"
            onClick={() => setActive(2)}
          >
            Avaliações
          </h5>
          {active === 2 && <div className={`${styles.active_indicator}`} />}
        </div>
        <div className="relative">
          <h5
            className="text-[#000] text-lg px-1 leading-5 font-semibold cursor-pointer"
            onClick={() => setActive(3)}
          >
            Informações do Vendedor
          </h5>
          {active === 3 && <div className={`${styles.active_indicator}`} />}
        </div>
      </div>

      {active === 1 && (
        <p className="py-2 text-lg leading-8 pb-10 whitespace-pre-line">{data.description}</p>
      )}

      {active === 2 && (
        <div className="w-full min-h-[40vh] flex flex-col items-center py-3 overflow-y-scroll">
          {data.reviews.map((item, index) => (
            <div className="w-full flex my-2" key={index}>
              <img src={item.user.avatar?.url} alt="" className="w-[50px] h-[50px] rounded-full" />
              <div className="pl-2">
                <div className="w-full flex items-center">
                  <h1 className="font-medium mr-3">{item.user.name}</h1>
                  <Ratings rating={item.rating} />
                </div>
                <p>{item.comment}</p>
              </div>
            </div>
          ))}

          {data.reviews.length === 0 && <h5>No Reviews for this product!</h5>}
        </div>
      )}

      {active === 3 && (
        <div className="w-full block 800px:flex p-5">
          <div className="w-full 800px:w-[50%]">
            <Link to={`/shop/preview/${data.shop._id}`}>
              <div className="flex items-center">
                <img src={data.shop.avatar?.url} className="w-[50px] h-[50px] rounded-full" alt="" />
                <div className="pl-3">
                  <h3 className={`${styles.shop_name} text-lg font-semibold`}>{data.shop.name}</h3>
                  <h5 className="pb-2 text-sm">({averageRating}/5) Ratings</h5>
                </div>
              </div>
            </Link>
            <p className="pt-2">{data.shop.description}</p>
          </div>
          <div className="w-full 800px:w-[50%] mt-5 800px:mt-0 800px:flex flex-col items-end">
            <div className="text-left">
              <h5 className="font-semibold">
                Joined on: <span className="font-medium">{data.shop.createdAt.slice(0, 10)}</span>
              </h5>
              <h5 className="font-semibold pt-3">
                Total Products: <span className="font-medium">{products.length}</span>
              </h5>
              <h5 className="font-semibold pt-3">
                Total Reviews: <span className="font-medium">{totalReviewsLength}</span>
              </h5>
              <Link to="/">
                <div className={`${styles.button} !rounded !h-[39.5px] mt-3`}>
                  <h4 className="text-white">Visit Shop</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
