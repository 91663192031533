// navigation Data
export const navItems = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Melhor venda",
      url: "/best-selling",
    },
    {
      title: "Produtos",
      url: "/products",
    },
    {
      title: "Eventos",
      url: "/events",
    },
    {
      title: "FAQ",
      url: "/faq",
    },
  ];
  
  // branding data
  export const brandingData = [
    {
      id: 1,
      title: "Free Shipping",
      Description: "From all orders over 100$",
      icon: (
        <svg
          width="36"
          height="36"
          viewBox="0 0 36 36"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1H5.63636V24.1818H35"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M8.72763 35.0002C10.4347 35.0002 11.8185 33.6163 11.8185 31.9093C11.8185 30.2022 10.4347 28.8184 8.72763 28.8184C7.02057 28.8184 5.63672 30.2022 5.63672 31.9093C5.63672 33.6163 7.02057 35.0002 8.72763 35.0002Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M31.9073 35.0002C33.6144 35.0002 34.9982 33.6163 34.9982 31.9093C34.9982 30.2022 33.6144 28.8184 31.9073 28.8184C30.2003 28.8184 28.8164 30.2022 28.8164 31.9093C28.8164 33.6163 30.2003 35.0002 31.9073 35.0002Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M34.9982 1H11.8164V18H34.9982V1Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M11.8164 7.18164H34.9982"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
        </svg>
      ),
    },
    {
      id: 2,
      title: "Daily Surprise Offers",
      Description: "Save up to 25% off",
      icon: (
        <svg
          width="32"
          height="34"
          viewBox="0 0 32 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M31 17.4502C31 25.7002 24.25 32.4502 16 32.4502C7.75 32.4502 1 25.7002 1 17.4502C1 9.2002 7.75 2.4502 16 2.4502C21.85 2.4502 26.95 5.7502 29.35 10.7002"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M30.7 2L29.5 10.85L20.5 9.65"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
        </svg>
      ),
    },
    {
      id: 4,
      title: "Affortable Prices",
      Description: "Get Factory direct price",
      icon: (
        <svg
          width="32"
          height="35"
          viewBox="0 0 32 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7 13H5.5C2.95 13 1 11.05 1 8.5V1H7"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M25 13H26.5C29.05 13 31 11.05 31 8.5V1H25"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M16 28V22"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
          ></path>
          <path
            d="M16 22C11.05 22 7 17.95 7 13V1H25V13C25 17.95 20.95 22 16 22Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M25 34H7C7 30.7 9.7 28 13 28H19C22.3 28 25 30.7 25 34Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
        </svg>
      ),
    },
    {
      id: 5,
      title: "Pagamento seguro",
      Description: "100% protected payments",
      icon: (
        <svg
          width="32"
          height="38"
          viewBox="0 0 32 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.6654 18.667H9.33203V27.0003H22.6654V18.667Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M12.668 18.6663V13.6663C12.668 11.833 14.168 10.333 16.0013 10.333C17.8346 10.333 19.3346 11.833 19.3346 13.6663V18.6663"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
          <path
            d="M31 22C31 30.3333 24.3333 37 16 37C7.66667 37 1 30.3333 1 22V5.33333L16 2L31 5.33333V22Z"
            stroke="#FFBB38"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="square"
          ></path>
        </svg>
      ),
    },
  ];
  
  // categories data
  export const categoriesData = [
    {
      id: 1,
      title: "Computadores e Laptops",
      subTitle: "",
      image_Url:
        "https://cdn.shopify.com/s/files/1/1706/9177/products/NEWAppleMacbookProwithM1ProChip14InchLaptop2021ModelMKGQ3LL_A_16GB_1TBSSD_custommacbd.jpg?v=1659592838",
    },
    {
      id: 2,
      title: "Cosméticos",
      subTitle: "",
      image_Url:
        "https://indian-retailer.s3.ap-south-1.amazonaws.com/s3fs-public/2021-07/kosme1.png",
    },
    {
      id: 3,
      title: "Accesórios",
      subTitle: "",
      image_Url:
        "https://img.freepik.com/free-vector/ordering-goods-online-internet-store-online-shopping-niche-e-commerce-website-mother-buying-babies-clothes-footwear-toys-infant-accessories_335657-2345.jpg?w=2000",
    },
    /*
    {
      id: 4,
      title: "Roupas",
      subTitle: "",
      image_Url:
        "https://www.shift4shop.com/2015/images/industries/clothing/clothing-apparel.png",
    },
    {
      id: 5,
      title: "Sapatos",
      subTitle: "",
      image_Url:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvBQPQMVNRd6TtDkGs2dCri0Y-rxKkFOiEWw&usqp=CAU",
    },
   {
      id: 6,
      title: "Presentes",
      subTitle: "",
      image_Url:
        "https://securecdn.pymnts.com/wp-content/uploads/2014/11/Gifts-Photo-700x489.jpg",
    },
    {
      id: 7,
      title: "Pet Care",
      subTitle: "",
      image_Url: "https://cdn.openpr.com/T/c/Tc15444071_g.jpg",
    },

    */
    {
      id: 8,
      title: "Eletrônicos",
      subTitle: "",
      image_Url:
        "https://st-troy.mncdn.com/mnresize/1500/1500/Content/media/ProductImg/original/mpwp3tua-apple-iphone-14-256gb-mavi-mpwp3tua-637986832343472449.jpg",
    },
    {
      id: 9,
      title: "Musicas e jogos",
      subTitle: "",
      image_Url:
        "https://static.vecteezy.com/system/resources/previews/011/996/555/original/3d-black-headphone-illustration-ecommerce-icon-png.png",
    },
    {
      id: 10,
      title: "Motores",
      subTitle: "",
      image_Url:
      "https://res.cloudinary.com/dnacdvawj/image/upload/v1721036181/products/wam7xinihghm0byn6gu8.jpg",
    },
    {
      id: 11,
      title: "Material de Construção",
      subTitle: "",
      image_Url:
        "https://res.cloudinary.com/dnacdvawj/image/upload/v1721036330/products/ukuhclqb0ngecuyecb8v.jpg",
    },
    {
      id: 12,
      title: "Bebidas",
      subTitle: "",
      image_Url:
        "https://res.cloudinary.com/dnacdvawj/image/upload/v1721036482/products/orwvb9rwmosretb5gwcu.png",
    },
    {
      id: 13,
      title: "Alimentos",
      subTitle: "",
      image_Url:
        "https://res.cloudinary.com/dnacdvawj/image/upload/v1721036837/products/oiwmkor1hdb04tfm7anr.webp",
    },
    {
      id: 14,
      title: "Outros",
      subTitle: "",
      image_Url:
        "https://searchspring.com/wp-content/uploads/2022/10/Hero-Image-Platform-Others-2.png",
    },
  ];
  
  export const footerProductLinks = [
    {
      name: "Sobre nós",
      link: "/about"
    },
    {
      name: "Careers",
      link: "/carrers"
    },
    {
      name: "Localização das lojas",
      link: "/location"
    },
    
  ];
  
  export const footercompanyLinks = [
   /* {
      name: "Game & Video",
    },
    {
      name: "Phone &Tablets",
    },
    {
      name: "Computers & Laptop",
    },
    {
      name: "Sport Watches",
    },
    {
      name: "Events",
    },*/
  ];
  
  export const footerSupportLinks = [
    {
      name: "FAQ",
    },
    {
      name: "Reviews",
    },
    {
      name: "Contact Us",
    },
    {
      name: "Shipping",
    },
    {
      name: "Live chat",
    },
  ];
  