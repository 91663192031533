import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";

const DropDown = ({ categoriesData, setDropDown }) => {
  const navigate = useNavigate();
  const dropDownRef = useRef(null);

  const submitHandle = (i) => {
    navigate(`/products?category=${i.title}`);
    setDropDown(false);
    window.location.reload();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setDropDown]);

  return (
    <div
      className="pb-4 w-[270px] bg-[#fff] absolute z-30 rounded-b-md shadow-sm max-h-[400px] overflow-y-auto"
      ref={dropDownRef}
    >
      {categoriesData &&
        categoriesData.map((i, index) => (
          <div
            key={index}
            className={`${styles.normalFlex} cursor-pointer p-3 hover:bg-gray-200`}
            onClick={() => submitHandle(i)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                submitHandle(i);
              }
            }}
            tabIndex={0}
            role="button"
            aria-label={`Navigate to ${i.title}`}
          >
            <h3 className="select-none">{i.title}</h3>
          </div>
        ))}
    </div>
  );
};

export default DropDown;
