import React from "react";
import { useNavigate } from "react-router-dom";
import { brandingData, categoriesData } from "../../../static/data";
import styles from "../../../styles/styles";

const Categories = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`${styles.section} bg-white p-4 md:p-6 rounded-lg mb-6 md:mb-12 mt-6`} // Aumente a margem superior aqui
        id="categories"
      >
        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 lg:grid-cols-4 lg:gap-6 xl:grid-cols-5 xl:gap-8">
          {categoriesData &&
            categoriesData.map((i) => {
              const handleSubmit = (i) => {
                navigate(`/products?category=${i.title}`);
              };
              return (
                <div
                  className="w-full h-[80px] md:h-[100px] flex items-center justify-between cursor-pointer overflow-hidden p-2 md:p-3"
                  key={i.id}
                  onClick={() => handleSubmit(i)}
                >
                  <h5 className={`text-[14px] md:text-[18px] leading-[1.3]`}>
                    {i.title}
                  </h5>
                  <img
                    src={i.image_Url}
                    className="w-[80px] md:w-[120px] object-cover"
                    alt={i.title}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Categories;
