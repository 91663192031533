import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../styles/styles";
import { categoriesData } from "../../static/data";
import {
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosArrowForward,
} from "react-icons/io";
import { BiMenuAltLeft } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import DropDown from "./DropDown";
import Navbar from "./Navbar";
import { useSelector } from "react-redux";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { RxCross1 } from "react-icons/rx";
import debounce from "lodash.debounce";
import { server } from "../../server";

const Header = ({ activeHeading }) => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [active, setActive] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [open, setOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(true);

  const trackSearchTerm = async (term) => {
    try {
      await axios.post(`${server}/search/save-search-term`, { term });
    } catch (error) {
      console.error("Error tracking search term:", error);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchTerm.trim() !== "") {
      trackSearchTerm(searchTerm);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearchSubmit();
    }
  };

  const debouncedSearch = useCallback(
    debounce((term) => {
      if (term.trim() === "") {
        setSearchData([]);
      } else {
        const filteredProducts = allProducts.filter((product) =>
          product.name.toLowerCase().includes(term.toLowerCase())
        );
        setSearchData(filteredProducts);
      }
    }, 300),
    [allProducts]
  );

  useEffect(() => {
    const handleScroll = () => {
      setActive(window.scrollY > 70);
      setShowSearch(window.scrollY <= 70);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleNavigation = (path) => {
    navigate(path);
  };

  const toggleDropDown = () => {
    setDropDown((prev) => !prev);
  };

  return (
    <>
      <div className={`${styles.section}`}>
        <div className="hidden 800px:flex items-center justify-between h-[50px] my-[20px]">
          <div className="flex-shrink-0">
            <img
              src="https://res.cloudinary.com/dnacdvawj/image/upload/v1713217800/emncfn0lne0jgurrbyu7.png"
              alt="Logo"
              className="h-[80px] w-auto cursor-pointer"
              onClick={() => handleNavigation("/")}
            />
          </div>
          {/* search box */}
          <div className="w-[50%] relative mx-auto">
            <input
              type="text"
              placeholder="Procurar produtos..."
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
            />
            <AiOutlineSearch
              size={30}
              className="absolute right-2 top-1.5 cursor-pointer"
              onClick={handleSearchSubmit}
            />
            {searchData.length > 0 && (
              <div className="absolute min-h-[30vh] max-h-[50vh] overflow-y-auto bg-white shadow-md z-10 p-4 w-full top-full mt-1 rounded-b-md">
                {searchData.map((product) => (
                  <div
                    key={product._id}
                    className="w-full flex items-start py-3 cursor-pointer hover:bg-gray-100"
                    onClick={() => handleNavigation(`/product/${product._id}`)}
                  >
                    <img
                      src={product.images[0]?.url}
                      alt={product.name}
                      className="w-[40px] h-[40px] mr-[10px] object-cover"
                    />
                    <h1 className="text-sm text-gray-800">{product.name}</h1>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`${
          active ? "shadow-sm fixed top-0 left-0 z-10" : ""
        } transition hidden 800px:flex items-center justify-between w-full bg-[#3321c8] h-[70px]`}
      >
        <div
          className={`${styles.section} relative flex justify-between items-center`}
        >
          {/* categories */}
          <div onClick={toggleDropDown}>
            <div className="relative h-[60px] mt-[10px] w-[270px] hidden 1000px:block">
              <BiMenuAltLeft size={30} className="absolute top-3 left-2" />
              <button className="h-[100%] w-full flex justify-between items-center pl-10 bg-white font-sans text-lg font-[500] select-none rounded-t-md">
                Todas as Categorias
              </button>
              {dropDown ? (
                <IoIosArrowUp
                  size={20}
                  className="absolute right-2 top-4 cursor-pointer"
                />
              ) : (
                <IoIosArrowDown
                  size={20}
                  className="absolute right-2 top-4 cursor-pointer"
                />
              )}
              {dropDown && (
                <DropDown
                  categoriesData={categoriesData}
                  setDropDown={setDropDown}
                />
              )}
            </div>
          </div>
          {/* navitems */}
          <div className="flex items-center">
            <Navbar active={activeHeading} />
          </div>

          <div className="flex items-center">
            <div
              className="relative cursor-pointer mr-[15px]"
              onClick={() => setOpenWishlist(true)}
            >
              <AiOutlineHeart size={30} color="rgb(255 255 255 / 83%)" />
              <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                {wishlist?.length}
              </span>
            </div>

            <div
              className="relative cursor-pointer mr-[15px]"
              onClick={() => setOpenCart(true)}
            >
              <AiOutlineShoppingCart size={30} color="rgb(255 255 255 / 83%)" />
              <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
                {cart?.length}
              </span>
            </div>

            <div className="relative cursor-pointer mr-[15px]">
              {isAuthenticated ? (
                <img
                  src={`${user?.avatar?.url}`}
                  className="w-[35px] h-[35px] rounded-full"
                  alt="User Avatar"
                  onClick={() => handleNavigation("/profile")}
                />
              ) : (
                <CgProfile
                  size={30}
                  color="rgb(255 255 255 / 83%)"
                  onClick={() => handleNavigation("/login")}
                />
              )}
            </div>

            {/* cart popup */}
            {openCart && <Cart setOpenCart={setOpenCart} />}

            {/* wishlist popup */}
            {openWishlist && <Wishlist setOpenWishlist={setOpenWishlist} />}
          </div>
        </div>
      </div>

      {/* mobile header */}
      <div
        className={`${
          active ? "shadow-sm fixed top-0 left-0 z-10" : ""
        } w-full h-[60px] bg-[#fff] z-50 top-0 left-0 shadow-sm 800px:hidden`}
      >
        <div className="w-full flex items-center justify-between px-4 ">
          <BiMenuAltLeft
            size={40}
            className="cursor-pointer"
            onClick={() => setOpen(!open)}
          />
          <div>
            <img
              src="https://res.cloudinary.com/dnacdvawj/image/upload/v1713217800/emncfn0lne0jgurrbyu7.png"
              alt="Logo"
              className="h-[40px] w-auto cursor-pointer"
              onClick={() => handleNavigation("/")}
            />
          </div>
          <div
            className="relative cursor-pointer mr-[15px]"
            onClick={() => setOpenWishlist(true)}
          >
            <AiOutlineHeart size={30} color="grey" />
            <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
              {wishlist?.length}
            </span>
          </div>
          <div
            className="relative cursor-pointer mr-[15px]"
            onClick={() => setOpenCart(true)}
          >
            <AiOutlineShoppingCart size={30} color="grey" />
            <span className="absolute right-0 top-0 rounded-full bg-[#3bc177] w-4 h-4 p-0 m-0 text-white font-mono text-[12px] leading-tight text-center">
              {cart?.length}
            </span>
          </div>
          <div className="relative cursor-pointer mr-[15px]">
            {isAuthenticated ? (
              <img
                src={`${user?.avatar?.url}`}
                className="w-[35px] h-[35px] rounded-full"
                alt="User Avatar"
                onClick={() => handleNavigation("/profile")}
              />
            ) : (
              <CgProfile
                size={30}
                color="rgb(255 255 255 / 83%)"
                onClick={() => handleNavigation("/login")}
              />
            )}
          </div>
        </div>
        {/* search box */}
        {showSearch && (
          <div className="w-full px-4 py-2 mt-4 600px:mt-6 mb-6">
            <div className="relative">
              <input
                type="text"
                placeholder="Procurar produtos..."
                value={searchTerm}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                className="h-[40px] w-full px-2 border-[#3957db] border-[2px] rounded-md"
              />
              <AiOutlineSearch
                size={30}
                className="absolute right-2 top-1.5 cursor-pointer"
                onClick={handleSearchSubmit}
              />
              {searchData.length > 0 && (
                <div className="absolute min-h-[30vh] max-h-[50vh] overflow-y-auto bg-white shadow-md z-10 p-4 w-full top-full mt-1 rounded-b-md">
                  {searchData.map((product) => (
                    <div
                      key={product._id}
                      className="w-full flex items-start py-3 cursor-pointer hover:bg-gray-100"
                      onClick={() => handleNavigation(`/product/${product._id}`)}
                    >
                      <img
                        src={product.images[0]?.url}
                        alt={product.name}
                        className="w-[40px] h-[40px] mr-[10px] object-cover"
                      />
                      <h1 className="text-sm text-gray-800">{product.name}</h1>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {open && (
        <div className="w-full bg-white shadow fixed top-0 left-0 z-50 h-full overflow-y-auto">
          <div className="flex w-full justify-between px-4 py-3">
            <img
              src="https://res.cloudinary.com/dnacdvawj/image/upload/v1713217800/emncfn0lne0jgurrbyu7.png"
              alt="Logo"
              className="h-[40px] w-auto cursor-pointer"
              onClick={() => handleNavigation("/")}
            />
            <RxCross1
              size={30}
              className="cursor-pointer"
              onClick={() => setOpen(false)}
            />
          </div>
          <Navbar active={activeHeading} />
       
        </div>
      )}
    </>
  );
};

export default Header;
