import { GET_PRODUCT_STATISTICS } from "../constants/statsConstants";

const initialState = { stats: [] };

export const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT_STATISTICS:
      return { ...state, stats: action.payload };
    default:
      return state;
  }
};
