import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../../../styles/styles";
import ProductCard from "../ProductCard/ProductCard";

const BestDeals = () => {
  const [data, setData] = useState([]);
  const { allProducts } = useSelector((state) => state.products);

  useEffect(() => {
    if (allProducts && allProducts.length > 0) {
      const allProductsData = [...allProducts];

      // Adiciona a contagem de visualizações para cada produto
      const productsWithViews = allProductsData.map(product => ({
        ...product,
        viewsCount: product.views ? product.views.length : 0
      }));

      // Ordena os produtos pela contagem de visualizações
      const sortedData = productsWithViews.sort((a, b) => b.viewsCount - a.viewsCount);

      // Seleciona os 10 principais produtos mais visualizados
      const topTen = sortedData.slice(0, 10);
      setData(topTen);
    }
  }, [allProducts]);

  return (
    <div className={`${styles.section} p-4`}>
      <div className={`${styles.heading} text-center`}>
        <h1 className="text-2xl md:text-3xl font-bold">Mais vistos</h1>
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:gap-6 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 mb-12">
        {data && data.length !== 0 && (
          <>
            {data.map((product, index) => (
              <ProductCard data={product} key={index} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default BestDeals;
