import React, { useEffect, useState } from "react";
import axios from "axios";
import { server } from "../server";

const MessageList = ({ data, setOpen, setCurrentChat, me, online, setActiveStatus }) => {
  const [shopData, setShopData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchShopData = async () => {
      const sellerId = data.members.find((m) => m !== me);
      try {
        const response = await axios.get(`${server}/shop/get-shop-info/${sellerId}`);
        setShopData(response.data.shop);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchShopData();
  }, [data, me]);

  const handleClick = () => {
    setCurrentChat(data);
    setOpen(true);
    if (shopData) {
      setActiveStatus(online);
    }
  };

  return (
    <div
      className="w-full flex items-center p-3 border-b border-gray-300 cursor-pointer hover:bg-gray-200"
      onClick={handleClick}
    >
      {isLoading ? (
        <div className="w-12 h-12 rounded-full bg-gray-200 animate-pulse"></div>
      ) : (
        <img
          src={shopData?.avatar.url}
          alt="Seller Avatar"
          className="w-12 h-12 rounded-full"
        />
      )}
      <div className="ml-3">
        <h1 className="text-lg font-semibold">{shopData?.name || 'Loading...'}</h1>
        <p className="text-sm text-gray-500">{data.lastMessage}</p>
      </div>
      {online && <span className="ml-auto text-green-500 text-sm">Online</span>}
    </div>
  );
};

export default MessageList;
