import React, { useState, useEffect } from "react";
import { AiOutlinePlusCircle, AiOutlineDelete } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { updateProduct } from "../../redux/actions/product";
import { categoriesData } from "../../static/data";
import { server } from "../../server";
import { toast } from "react-toastify";

const UpdateProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [product, setProduct] = useState(null);
  const [images, setImages] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [tags, setTags] = useState("");
  const [originalPrice, setOriginalPrice] = useState("");
  const [discountPrice, setDiscountPrice] = useState("");
  const [stock, setStock] = useState("");

  useEffect(() => {
    axios
      .get(`${server}/product/product/${id}`, { withCredentials: true })
      .then((res) => {
        const fetchedProduct = res.data.product;
        setProduct(fetchedProduct);
        setName(fetchedProduct.name);
        setDescription(fetchedProduct.description);
        setCategory(fetchedProduct.category);
        setTags(fetchedProduct.tags);
        setOriginalPrice(fetchedProduct.originalPrice);
        setDiscountPrice(fetchedProduct.discountPrice);
        setStock(fetchedProduct.stock);
        setImages(fetchedProduct.images.map((image) => ({ url: image.url, size: 0 }))); // Set size to 0 for existing images
      })
      .catch((err) => {
        console.error(err);
        toast.error("Error fetching product data.");
      });
  }, [id]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => file.size <= 2 * 1024 * 1024); // 2MB limit
    const invalidFiles = files.filter((file) => file.size > 2 * 1024 * 1024);

    if (invalidFiles.length > 0) {
      toast.error("Some files are too large and were not added.");
    }

    if (validFiles.length + images.length > 4) {
      toast.error("You can only upload up to 4 images.");
      return;
    }

    validFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.readyState === 2) {
          setImages((old) => [...old, { url: reader.result, size: file.size }]);
        }
      };
      reader.readAsDataURL(file);
    });
  };

  const handleRemoveImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedData = new FormData();

    images.forEach((image) => {
      if (image.size > 0) {
        updatedData.append("images", image.url);
      }
    });
    updatedData.append("name", name);
    updatedData.append("description", description);
    updatedData.append("category", category);
    updatedData.append("tags", tags);
    updatedData.append("originalPrice", originalPrice);
    updatedData.append("discountPrice", discountPrice);
    updatedData.append("stock", stock);
    updatedData.append("shopId", product.shop._id);

    dispatch(
      updateProduct(id, {
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId: product.shop._id,
        images: images.map((image) => image.url),
      })
    )
      .then(() => {
        toast.success("Product updated successfully!");
        // navigate("/dashboard"); // Uncomment if you want to navigate after update
        // window.location.reload(); // Uncomment if you want to reload after update
      })
      .catch((err) => {
        toast.error("Error updating product.");
        console.error(err);
      });
  };

  return (
    <div className="w-[90%] 800px:w-[50%] bg-white shadow h-[80vh] rounded-[4px] p-3 overflow-y-scroll">
      <h5 className="text-[30px] font-Poppins text-center">Update Product</h5>
      <form onSubmit={handleSubmit}>
        <br />
        <div>
          <label className="pb-2">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            value={name}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your product name..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Description <span className="text-red-500">*</span>
          </label>
          <textarea
            cols="30"
            rows="8"
            name="description"
            value={description}
            className="mt-2 appearance-none block w-full pt-2 px-3 border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter your product description..."
            required
          ></textarea>
        </div>
        <br />
        <div>
          <label className="pb-2">
            Category <span className="text-red-500">*</span>
          </label>
          <select
            className="w-full mt-2 border h-[35px] rounded-[5px]"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Choose a category</option>
            {categoriesData &&
              categoriesData.map((i) => (
                <option value={i.title} key={i.title}>
                  {i.title}
                </option>
              ))}
          </select>
        </div>
        <br />
        <div>
          <label className="pb-2">Tags</label>
          <input
            type="text"
            name="tags"
            value={tags}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setTags(e.target.value)}
            placeholder="Enter your product tags..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">Original Price</label>
          <input
            type="number"
            name="originalPrice"
            value={originalPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setOriginalPrice(e.target.value)}
            placeholder="Enter your product price..."
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Price (With Discount) <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="discountPrice"
            value={discountPrice}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setDiscountPrice(e.target.value)}
            placeholder="Enter your product price with discount..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Product Stock <span className="text-red-500">*</span>
          </label>
          <input
            type="number"
            name="stock"
            value={stock}
            className="mt-2 appearance-none block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            onChange={(e) => setStock(e.target.value)}
            placeholder="Enter your product stock..."
            required
          />
        </div>
        <br />
        <div>
          <label className="pb-2">
            Upload Images <span className="text-red-500">*</span>
          </label>
          <input
            type="file"
            id="upload"
            className="hidden"
            multiple
            onChange={handleImageChange}
            accept="image/*"
          />
          <div className="w-full flex items-center flex-wrap">
            <label htmlFor="upload">
              <AiOutlinePlusCircle size={30} className="mt-3" color="#555" />
            </label>
            {images &&
              images.map((image, index) => (
                <div key={index} className="relative m-2">
                  <img
                    src={image.url}
                    alt={`upload-${index}`}
                    className="h-[120px] w-[120px] object-cover"
                  />
                  <button
                    type="button"
                    className="absolute top-1 right-1 bg-white rounded-full"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <AiOutlineDelete size={20} color="red" />
                  </button>
                  <p className="text-xs text-gray-500">
                    {(image.size / 1024 / 1024).toFixed(2)} MB
                  </p>
                </div>
              ))}
          </div>
        </div>
        <br />
        <div>
          <input
            type="submit"
            value="Update"
            className="mt-2 cursor-pointer appearance-none text-center block w-full px-3 h-[35px] border border-gray-300 rounded-[3px] placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            disabled={images.length === 0 || images.length > 4}
          />
        </div>
      </form>
    </div>
  );
};

export default UpdateProduct;
